import styled from 'styled-components'
import { Row as ReacstrapRow, Col as ReacstrapCol } from 'reactstrap'

export const Row = styled(ReacstrapRow).attrs({
  className: 'mx-0 px-0',
})``

export const Col = styled(ReacstrapCol).attrs({
  className: 'mx-0 px-0',
})``
