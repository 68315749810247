import React from 'react'
import Select from 'react-select'

const customStyles = {
  container: provided => ({
    ...provided,
    minWidth: '200px',
    borderRadius: '8px',
    margin: '5px 20px',
    border: '1px solid #EFEFEF',
    fontSize: '12px',
    color: '#606060',
    fontWeight: '500',
    lineHeight: '14px',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    paddingLeft: '30px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    color: state.isSelected ? '#FFF' : '#626262',
    backgroundColor: state.isSelected
      ? '#7367f0'
      : state.isFocused
      ? '#f2f2f2'
      : 'transparent',
  }),

  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),

  singleValue: (provided, { selectProps, isDisabled }) => ({
    ...provided,
    opacity: isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    zIndex: 2,
    fontSize: selectProps.menuIsOpen ? '0' : 'font-size: 12px',
  }),

  menu: base => ({
    ...base,
    zIndex: 1000,
  }),

  menuList: base => ({
    ...base,
    transition: 'all 2.75s ease-in-out',
    transform: '',
    overflowY: 'auto !important',
    zIndex: 1000,
    boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.15)',
  }),
}

function SwitchProjects(props) {
  return (
    <>
      <Select
        {...props}
        isClearable={false}
        isSearchable
        styles={customStyles}
        menuShouldScrollIntoView={true}
        hideSelectedOptions={false}
        onBlurResetsInput={true}
        // menuPortalTarget={document.getElementById('navigation-main')}
      />
    </>
  )
}

export default SwitchProjects
