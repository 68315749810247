import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Collapse, FormText } from 'reactstrap'
import { useField } from 'formik'
import _ from 'lodash'

import { Hide, Show } from '~/components/commons'

function Field(
  {
    render: Render,
    name,
    label,
    helperText = '',
    className,
    onChange,
    errorExtractor,
    required = false,
    enableBlurAction = true,
    showHelperText = true,
    ...props
  },
  ref
) {
  const [
    { onChange: handleFormikChange, onBlur, ...inputProps },
    meta,
    helpers,
  ] = useField({
    name,
    type: props.type || 'text',
  })

  const error = useMemo(
    () => (_.isObject(meta.error) ? errorExtractor(meta.error) : meta.error),
    [meta.error, errorExtractor]
  )

  return (
    <div style={{ width: '100%' }}>
      <label className="font-small-3" htmlFor={name}>
        {label && <FormattedMessage id={label} />}
        <Show condition={required && _.isEmpty(error)}>&nbsp;*</Show>
        <Show condition={!required && _.isEmpty(error)}>
          &nbsp; (<FormattedMessage id="optional" />)
        </Show>
        <Hide condition={_.isEmpty(error)}>
          <strong className="text-danger">- {error}</strong>
        </Hide>
      </label>
      <Render
        ref={ref}
        id={name}
        className={`${className} ${inputProps.className}`}
        onChange={event =>
          _.isFunction(onChange)
            ? onChange(event, helpers)
            : handleFormikChange(event)
        }
        invalid={!_.isEmpty(error)}
        {...(enableBlurAction && { onBlur })}
        {...inputProps}
        {...props}
      />
      <Collapse isOpen={!!helperText && showHelperText}>
        <FormText className="text-bold-500">
          <Show condition={!!helperText}>
            <FormattedMessage id={helperText} />
          </Show>
        </FormText>
      </Collapse>
    </div>
  )
}

export default React.forwardRef(Field)
