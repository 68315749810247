import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Show } from './commons'

function LabelRenderer({ label, intl, labelStyle }) {
  return (
    <span
      style={{
        width: 'max-content',
        marginRight: 15,
        maxWidth: 'calc(100% - 38px)',
        wordBreak: 'break-all',
        ...labelStyle,
      }}
    >
      {intl ? <FormattedMessage id={label} /> : <>{label}</>}
    </span>
  )
}

function CheckBox({
  className,
  color,
  checked,
  defaultChecked,
  value,
  disabled,
  onClick,
  onChange,
  size,
  icon,
  label,
  labelStyle,
  spanStyle,
  render: Render = LabelRenderer,
  intl = true,
  children,
}) {
  return (
    <div
      style={{ zIndex: 0 }}
      className={`vx-checkbox-con ${className || ''} vx-checkbox-${color}`}
    >
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        value={value}
        disabled={disabled}
        onClick={onClick || null}
        onChange={onChange || null}
      />
      <span
        className={`vx-checkbox vx-checkbox-${size || 'md'}`}
        style={spanStyle}
      >
        <span className="vx-checkbox--check">{icon}</span>
      </span>
      <Show condition={!!label}>
        <Render label={label} intl={intl} labelStyle={labelStyle} />
      </Show>
      <Show condition={children}>{children}</Show>
    </div>
  )
}

export default CheckBox
