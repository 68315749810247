import styled from 'styled-components'

export default styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #d5d5d5;
  }
  &::before {
    margin-right: 1em;
  }
  &::after {
    margin-left: 1em;
  }
`
