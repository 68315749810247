import React from 'react'
import classnames from 'classnames'

import { FormattedMessage } from 'react-intl'

const Footer = props => {
  const footerTypeArr = ['sticky', 'static', 'hidden']
  return (
    <>
      <footer
        className={
          'd-flex ' +
          classnames('footer footer-light', {
            'footer-static':
              props.footerType === 'static' ||
              !footerTypeArr.includes(props.footerType),
            'd-none': props.footerType === 'hidden',
          })
        }
      >
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
            Copyright © {new Date().getFullYear()}{' '}
            <FormattedMessage id="Locasty, All rights reserved" />
          </span>
        </p>
      </footer>
    </>
  )
}

export default Footer
