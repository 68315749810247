import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { ErrorBoundary } from 'react-error-boundary'

import { Auth0Provider } from './authServices/auth0/auth0Service'
import config from './authServices/auth0/auth0Config.json'
import { IntlProviderWrapper } from './utility/context/Internationalization'
import ThemeProvider from './utility/context/theme'
import { Layout } from './utility/context/Layout'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import Spinner from './components/@vuexy/spinner/Loading-spinner'
import './index.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-number-input/style.css'

import './@fake-db'
import ModalCreateProjectByInvite from './components/ModalCreateProjectByInvite'
import { ErrorPage, logError } from './components/ErrorPage'
import { AppErrorPage } from './components/AppErrorPage'

const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <ErrorBoundary fallbackRender={AppErrorPage} onError={logError}>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin + process.env.REACT_APP_PUBLIC_PATH}
    >
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeProvider>
            <Layout>
              <IntlProviderWrapper>
                <ErrorBoundary fallbackRender={ErrorPage} onError={logError}>
                  <ToastContainer />
                  <ModalCreateProjectByInvite />
                  <LazyApp />
                </ErrorBoundary>
              </IntlProviderWrapper>
            </Layout>
          </ThemeProvider>
        </Suspense>
      </Provider>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

serviceWorker.unregister()
