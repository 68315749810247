import React from 'react'
import styled from 'styled-components'
import { Spinner, Card } from 'reactstrap'
import { queries } from '~/constants/mediaQuery'
import { Show } from './display'

function LoadableCard({ loading, children, yOffset = '', ...props }) {
  return (
    <LoadableCard.Container loading={loading} yOffset={yOffset} {...props}>
      <Show condition={loading}>
        <LoadableCard.Backdrop>
          <Spinner color="primary" size="lg" className="reload-spinner" />
        </LoadableCard.Backdrop>
      </Show>
      {children}
    </LoadableCard.Container>
  )
}

LoadableCard.FilteredCard = ({
  loading: _loading,
  yOffset: _yOffset,
  children,
  ...props
}) => {
  return <Card {...props}>{children}</Card>
}

LoadableCard.Container = styled(LoadableCard.FilteredCard)`
  width: 100%;
  margin: 0;
  border-radius: 1rem;

  .reload-spinner {
    position: absolute;
    right: calc(50% - 1rem);
    bottom: calc(
      50% - 1rem ${({ yOffset }) => (yOffset ? `+ ${yOffset}` : '')}
    );
    z-index: 10;
    transition: all 2s;
  }

  .card-body {
    opacity: ${props => (props.loading ? 0.5 : 1)};
  }

  ${props =>
    props.disableMediaQuery
      ? ''
      : `@media ${queries.less.md} {
    flex: 0 0 100% !important;
    max-width: unset !important;
  }`}
`

LoadableCard.Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.1);
`

export default LoadableCard
