import React, { useState } from 'react'
import Select, { components } from 'react-select'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import _ from 'lodash'

import Checkbox from './Checkbox'

const normalizeString = str => {
  const lower = _.lowerCase(str)
  return _.deburr(lower)
}

function Menu({ onChange, inputValue, selectValue, ...props }) {
  function handleSelectAll() {
    const input = normalizeString(inputValue?.trim())
    if (input.length) {
      onChange(
        props.options?.filter(option =>
          normalizeString(option.label).includes(input)
        )
      )
    } else {
      onChange(props.options)
    }
  }

  function handleUnselectAll() {
    const input = normalizeString(inputValue?.trim())
    if (input.length) {
      onChange(
        selectValue?.filter(
          option => !normalizeString(option.label).includes(input)
        )
      )
    } else {
      onChange([])
    }
  }

  return (
    <components.Menu {...props}>
      <MenuHeader>
        <Button
          style={{ padding: 0 }}
          className="text-primary"
          onClick={handleUnselectAll}
        >
          <FormattedMessage id="Unselect All" />
        </Button>
        <Button
          style={{ padding: 0 }}
          className="text-primary"
          onClick={handleSelectAll}
        >
          <FormattedMessage id="Select All" />
        </Button>
      </MenuHeader>
      {props.children}
    </components.Menu>
  )
}

function Option({
  label,
  value,
  selectValue = [],
  onChange,
  render,
  cx: _cx,
  ...props
}) {
  const handleClick = () => {
    const index = selectValue?.some(item =>
      item.value?.every(location => value?.includes(location))
    )

    if (!index) {
      return onChange([...selectValue, { value, label }])
    }

    onChange(
      selectValue.filter(
        item => !item.value?.every(location => value?.includes(location))
      )
    )
  }

  return (
    <OptionContainer onClick={handleClick} {...props}>
      <Checkbox
        id={props?.innerProps?.id}
        inline
        type="checkbox"
        size="sm"
        label={label}
        defaultChecked={selectValue.some(
          item =>
            item.value === value ||
            item.value?.every(selectedInnerValue =>
              value?.includes(selectedInnerValue)
            )
        )}
        render={render}
        intl={false}
      />
    </OptionContainer>
  )
}

function SelectedCounter({ selectValue }) {
  const selectedAmount = selectValue?.length

  return (
    <CountContainer>
      {selectedAmount > 0 && <small>{selectedAmount}</small>}
    </CountContainer>
  )
}

function MultiSelect({
  placeholder,
  value,
  onChange,
  renderOption,
  uniqueKey,
  options,
  ...props
}) {
  const [inputValue, setInputValue] = useState('')

  const filterOption = (option, inputValue) => {
    const label =
      typeof option?.label === 'string'
        ? normalizeString(option?.label)
        : normalizeString(option?.label?.text)

    return label.includes(normalizeString(inputValue))
  }

  function handleInputChange(value) {
    setInputValue(value)
  }

  return (
    <Container>
      <SelectedCounter selectValue={value}></SelectedCounter>
      <CustomSelect
        key={uniqueKey}
        options={options}
        placeholder={placeholder}
        classNamePrefix="react-select"
        menuPortalTarget={document.querySelector('body')}
        isMulti
        isSearchable
        isClearable={false}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        hideSelectedOptions={false}
        filterOption={filterOption}
        controlShouldRenderValue={false}
        onInputChange={handleInputChange}
        isLoading={props.loading || false}
        {...props}
        components={{
          Menu: p => (
            <Menu
              {...p}
              inputValue={inputValue}
              selectValue={value}
              onChange={onChange}
            />
          ),
          Option: p => (
            <Option
              {...p}
              selectValue={value}
              onChange={onChange}
              render={renderOption}
              placeholder={placeholder}
            />
          ),
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  min-width: 100px;
  width: 100%;
  position: relative;
`

const CustomSelect = styled(Select)`
  min-width: 100px;
  width: 100%;
`

const CountContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 50px;
  pointer-events: none;
  z-index: 1;

  small {
    width: 20px;
    height: 20px;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
`

const MenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 12px 20px;
  border-bottom: 1px solid #eee;
  cursor: default;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const OptionContainer = styled.div`
  text-align: start;
  padding: 5px 15px;
  cursor: pointer !important;
`

const Button = styled.button`
  border: none;
  outline: none;
  background: none;
`

export default MultiSelect
