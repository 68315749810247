import React from 'react'
import classnames from 'classnames'
import { Badge } from 'reactstrap'
import { User } from 'react-feather'

function Avatar(props) {
  const imageProp = props.image || props.img
  return (
    <div
      style={{ background: props.bgColor }}
      className={classnames(`avatar ${props.className}`, {
        'avatar-sm': props.size && props.size === 'sm',
        'avatar-lg': props.size && props.size === 'lg',
        'avatar-xl': props.size && props.size === 'xl',
      })}
    >
      {!imageProp ? (
        <span
          className={classnames('avatar-content', {
            'position-relative': props.badgeUp,
          })}
          style={{ width: 40, height: 40 }}
        >
          {props.content ? props.content : null}

          {props.icon ? (
            <div className="avatar-icon primary">
              <User />
            </div>
          ) : null}
          {props.badgeUp ? (
            <Badge
              color={props.badgeColor ? props.badgeColor : 'primary'}
              className="badge-sm badge-up"
              pill
            >
              {props.badgeText ? props.badgeText : '0'}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          src={imageProp}
          alt=""
          height={props.imgHeight && !props.size ? props.imgHeight : 40}
          width={props.imgWidth && !props.size ? props.imgWidth : 40}
        />
      )}
      {props.status ? (
        <span className={`avatar-status-${props.status}`}></span>
      ) : null}
    </div>
  )
}
export default Avatar
