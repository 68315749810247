import produce from 'immer'

const INITIAL_STATE = {
  profile: null,
  selectedProject: null,
}

function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS':
        const { user } = action.payload
        const sortedProjects = user?.projects?.sort((a, b) => {
          const normalizedA = a.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
          const normalizedB = b.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

          return a > b ? 1 : normalizedA < normalizedB ? -1 : 0
        })
        draft.profile = user
        draft.selectedProject = sortedProjects?.[0] ?? []
        break
      case '@user/UPDATE_PROFILE_SUCCESS':
        draft.profile = action.payload.profile
        break
      case '@auth/SIGN_OUT':
        draft.profile = null
        draft.selectedProject = null
        break
      case '@user/SWITCH_PROJECT':
        draft.selectedProject = state.profile?.projects.find(
          project => project.id === action.payload.id
        )
        break
      default:
    }
  })
}

export default user
