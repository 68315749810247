export const CLIENT = 0
export const CLIENT_ADMIN = 1
export const ADMIN = 2
export const SUPER_ADMIN = 3

export const ROLES = {
  [CLIENT]: 'Client',
  [CLIENT_ADMIN]: 'Client Admin',
  [ADMIN]: 'Admin',
  [SUPER_ADMIN]: 'Super Admin',
}
