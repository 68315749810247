import produce from 'immer'

const INITIAL_STATE = {
  access_token: null,
  refresh_token: null,
  signed: false,
  loading: false,
}

function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST':
        draft.loading = true
        break
      case '@auth/SIGN_IN_SUCCESS':
        draft.access_token = action.payload.access_token
        draft.refresh_token = action.payload.refresh_token
        draft.signed = true
        draft.loading = false
        break
      case '@auth/SIGN_FAILURE':
        draft.loading = false
        break
      case '@auth/SIGN_OUT':
        draft.signed = false
        draft.token = null
        break
      case '@auth/REFRESH_TOKEN':
        draft.access_token = action.payload.access_token
        draft.refresh_token = action.payload.refresh_token
        break
      default:
    }
  })
}

export default auth
