/* eslint-disable */
import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { ChevronDown, ChevronUp } from 'react-feather'

import Truncate from './Truncate'
import styled from 'styled-components'

class ShowMoreText extends Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      truncated: false,
    }
  }

  static defaultProps = {
    lines: 3,
    more: 'Show more',
    less: 'Show less',
    anchorClass: '',
    onClick: undefined,
    expanded: false,
    width: 0,
    keepNewLines: false,
  }

  static propTypes = {
    children: PropTypes.node,
    lines: PropTypes.number,
    more: PropTypes.node,
    less: PropTypes.node,
    anchorClass: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    expanded: PropTypes.bool,
    width: PropTypes.number,
    keepNewLines: PropTypes.bool,
  }

  componentDidMount() {
    this._isMounted = true

    var _self = this
    if (this._isMounted) {
      this.setState({
        expanded: _self.props.expanded,
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleTruncate = truncated => {
    if (this._isMounted && truncated !== this.state.truncated) {
      this.setState({
        truncated,
      })
      if (truncated) {
        this.truncateRef.onResize()
      }
    }
  }

  toggleLines = event => {
    event.preventDefault()
    var _self = this
    if (this._isMounted) {
      this.setState(
        {
          expanded: !this.state.expanded,
        },
        () => {
          if (_self.props.onClick) {
            _self.props.onClick(_self.state.expanded)
          }
        }
      )
    }
  }

  render() {
    const {
      children = '',
      more,
      less,
      lines,
      anchorClass,
      className,
      width,
      keepNewLines,
      style,
    } = this.props

    const { expanded, truncated } = this.state

    return (
      <div className={className} style={style}>
        <Truncate
          width={width}
          lines={!expanded && lines}
          expanded={expanded}
          ellipsis={
            <>
              ...
              <TruncateLinkContainer>
                <a href="" className={anchorClass} onClick={this.toggleLines}>
                  {more}
                  <span className="text-bold">
                    <ChevronDown size={15} />
                  </span>
                </a>
              </TruncateLinkContainer>
            </>
          }
          onTruncate={this.handleTruncate}
          ref={ref => (this.truncateRef = ref)}
        >
          {keepNewLines
            ? children.split('\n').map((line, i, arr) => {
                line = <span key={i}>{line}</span>

                if (i === arr.length - 1) {
                  return line
                } else {
                  return [line, <br key={i + 'br'} />]
                }
              })
            : children}
        </Truncate>
        {!truncated && expanded && (
          <TruncateLinkContainer>
            <a href="" className={anchorClass} onClick={this.toggleLines}>
              {less}
              <span className="text-bold">
                <ChevronUp size={15} />
              </span>
            </a>
          </TruncateLinkContainer>
        )}
      </div>
    )
  }
}

const TruncateLinkContainer = styled.span`
  display: block;

  a {
    display: flex;
    align-items: center;
    gap: 3px;
    color: ${props => props.theme.color.primary};
    width: max-content;
    margin-bottom: -5px;
  }
`

export default ShowMoreText
