import React from 'react'
import { ThemeProvider as Provider } from 'styled-components'

const theme = {
  text: {
    darker: '#2c2c2c',
    lighterGray: '#E5E5E5',
    gray: '#A8A8A8',
    white: '#fff',
    primary: '#7367f0',
  },
  color: {
    primary: '#7367f0',
    gray: '#A8A8A8',
    white: '#fff',
    danger: '#ea5455',
    info: '#16CFE8',
  },
}

function ThemeProvider({ children }) {
  return <Provider theme={theme}>{children}</Provider>
}

export default ThemeProvider
