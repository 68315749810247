import React from 'react'
import PT from 'prop-types'

Iterate.propTypes = {
  data: PT.array,
  keyExtractor: PT.func,
  render: PT.oneOfType([PT.func, PT.element]),
}

Iterate.defaultProps = {
  data: [],
  keyExtractor: (_, idx) => idx,
  render: () => <></>,
}

function Iterate({ data = [], keyExtractor, render: Render, ...props }) {
  return data.map((item, idx) => (
    <Render key={keyExtractor(item, idx)} item={item} {...props} />
  ))
}

export default Iterate
