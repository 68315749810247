import axios from 'axios'
export const loadSuggestions = () => {
  return () => {}
}

export const updateStarred = object => {
  return dispatch => {
    axios
      .post('api/update/bookmarks', {
        obj: object,
      })
      .then(() => {
        dispatch({
          type: '@navbar/UPDATE_STARRED',
          object,
        })
        dispatch(loadSuggestions())
      })
  }
}

export function toggleInviteModal() {
  return {
    type: '@navbar/TOGGLE_INVITE_MODAL',
  }
}
