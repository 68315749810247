import React from 'react'
import { useIntl } from 'react-intl'

import ShowMoreText from '~/lib/ShowMoreText'

function TextBox({ children = '', fontSize, ...props }) {
  const intl = useIntl()

  return (
    <div {...props}>
      <ShowMoreText
        style={{ fontSize }}
        lines={3}
        more={intl.formatMessage({ id: 'show more' })}
        less={intl.formatMessage({ id: 'show less' })}
        keepNewLines
      >
        {children}
      </ShowMoreText>
    </div>
  )
}

export default TextBox
