import {
  decodeDelimitedArray,
  encodeDelimitedArray,
  useQueryParams,
} from 'use-query-params'

// example for date https://github.com/pbeshai/use-query-params/issues/49

export const useQueryParamsReplace = config => {
  const [query, setQuery] = useQueryParams(config)

  const setQueryReplace = (newQuery, updateType = 'pushIn') =>
    setQuery(newQuery, updateType)

  return [query, setQueryReplace]
}

export const CommaArrayParam = {
  encode: array => encodeDelimitedArray(array, ','),

  decode: arrayStr => decodeDelimitedArray(arrayStr, ','),
}
