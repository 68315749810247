import React from 'react'
import { useSetState } from 'react-use'
import classnames from 'classnames'
import { ContextLayout } from '~/utility/context/Layout'
import { useDispatch, useSelector } from 'react-redux'
import SidebarHeader from './SidebarHeader'
import Hammer from 'react-hammerjs'
import SideMenuContent from './sidemenu/SideMenuContent'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { switchProject } from '~/store/modules/user/actions'
import SwitchProjects from '~/components/ecto/SwitchProjects'
import { Show } from '~/components/commons'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { useQueryParamsReplace } from '~/hooks/useQueryParams'

function Sidebar(props) {
  const [state, setState] = useSetState({
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: props.activePath,
    menuShadow: false,
    ScrollbarTag: PerfectScrollbar,
  })

  const currentUser = useSelector(state => state.user?.userRole)
  const userProfile = useSelector(state => state.user?.profile)
  const userPermissions = userProfile?.userPermission
  const localSelectedProject = JSON.parse(localStorage.getItem('state'))
    ?.selectedProject
  const selectedProject = useSelector(state => ({
    label: state.user?.selectedProject?.name,
    value: state.user?.selectedProject?.id,
  }))

  const userProjects = useSelector(state =>
    state.user?.profile?.projects?.map(project => ({
      label: project.name,
      value: project.id,
    }))
  )

  const dispatch = useDispatch()

  const [, setQueryParams] = useQueryParamsReplace({})

  React.useEffect(() => {
    if (localSelectedProject) {
      dispatch(switchProject(localSelectedProject))
      return
    }

    const previousState = JSON.parse(localStorage.getItem('state'))
    localStorage.setItem(
      'state',
      JSON.stringify({
        ...previousState,
        selectedProject: selectedProject.value,
      })
    )
    // eslint-disable-next-line
  }, [dispatch])

  React.useEffect(() => {
    const checkDevice = () => {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
      const mq = function (query) {
        return window.matchMedia(query).matches
      }

      if ('ontouchstart' in window || window.DocumentTouch) {
        setState({
          ScrollbarTag: 'div',
        })
      } else {
        setState({
          ScrollbarTag: PerfectScrollbar,
        })
      }
      const query = [
        '(',
        prefixes.join('touch-enabled),('),
        'heartz',
        ')',
      ].join('')
      return mq(query)
    }
    const updateWidth = () => {
      setState({
        width: window.innerWidth,
      })
      checkDevice()
    }

    if (window !== 'undefined') {
      window.addEventListener('resize', updateWidth, false)
    }
    checkDevice()

    return () => {
      window.removeEventListener('resize', updateWidth, false)
    }
  }, [setState])

  React.useEffect(() => {
    setState({ activeItem: props.activePath })
  }, [props.activePath, setState])

  const changeActiveIndex = id => {
    if (id !== state.activeIndex) {
      setState({
        activeIndex: id,
      })
    } else {
      setState({
        activeIndex: null,
      })
    }
  }

  const handleSidebarMouseEnter = id => {
    if (id !== state.hoveredMenuItem) {
      setState({
        hoveredMenuItem: id,
      })
    } else {
      setState({
        hoveredMenuItem: null,
      })
    }
  }

  const handleActiveItem = url => {
    setState({
      activeItem: url,
    })
  }

  const {
    visibilityState,
    toggleSidebarMenu,
    sidebarHover,
    toggle,
    color,
    sidebarVisibility,
    activeTheme,
    collapsed,
    activePath,
    sidebarState,
    currentLang,
    permission,
    collapsedMenuPaths,
  } = props

  const {
    menuShadow,
    activeIndex,
    hoveredMenuItem,
    activeItem,
    ScrollbarTag,
  } = state

  const scrollShadow = (container, dir) => {
    if (container && dir === 'up' && container.scrollTop >= 100) {
      setState({ menuShadow: true })
    } else if (container && dir === 'down' && container.scrollTop < 100) {
      setState({ menuShadow: false })
    }
  }
  return (
    <ContextLayout.Consumer>
      {context => {
        const dir = context.state.direction
        return (
          <>
            <Hammer
              onSwipe={() => {
                sidebarVisibility()
              }}
              direction={dir === 'rtl' ? 'DIRECTION_LEFT' : 'DIRECTION_RIGHT'}
            >
              <div className="menu-swipe-area d-xl-none d-block vh-100"></div>
            </Hammer>

            <div
              id="menu-sidebar-left"
              className={classnames(
                `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme}`,
                {
                  collapsed: sidebarState === true,
                  'hide-sidebar':
                    state.width < 1200 && visibilityState === false,
                }
              )}
              onMouseEnter={() => sidebarHover(false)}
              onMouseLeave={() => sidebarHover(true)}
            >
              <SidebarHeader
                toggleSidebarMenu={toggleSidebarMenu}
                toggle={toggle}
                sidebarBgColor={color}
                sidebarVisibility={sidebarVisibility}
                activeTheme={activeTheme}
                collapsed={collapsed}
                menuShadow={menuShadow}
                activePath={activePath}
                sidebarState={sidebarState}
              />
              <Show condition={userProjects?.length > 1}>
                <SwitchProjects
                  placeholder={<FormattedMessage id="Project Name" />}
                  uniqueKey="Project"
                  options={_.sortBy(userProjects, [proj => proj.label])}
                  value={selectedProject}
                  onChange={e => {
                    const previousState = JSON.parse(
                      localStorage.getItem('state')
                    )
                    localStorage.setItem(
                      'state',
                      JSON.stringify({
                        ...previousState,
                        selectedProject: e.value,
                      })
                    )
                    dispatch(switchProject(e.value))
                    setQueryParams({})
                  }}
                  toggleChevron={!collapsed || (collapsed && !sidebarState)}
                />
              </Show>
              <ScrollbarTag
                className={classnames('main-menu-content', {
                  'overflow-hidden': ScrollbarTag !== 'div',
                  'overflow-scroll': ScrollbarTag === 'div',
                })}
                {...(ScrollbarTag !== 'div' && {
                  options: { wheelPropagation: false },
                  onScrollDown: container => scrollShadow(container, 'down'),
                  onScrollUp: container => scrollShadow(container, 'up'),
                  onYReachStart: () =>
                    menuShadow === true && setState({ menuShadow: false }),
                })}
              >
                <Hammer
                  onSwipe={() => {
                    sidebarVisibility()
                  }}
                  direction={
                    dir === 'rtl' ? 'DIRECTION_RIGHT' : 'DIRECTION_LEFT'
                  }
                >
                  <div className="navigation-wrapper">
                    <ul
                      id="navigation-main"
                      className="navigation navigation-main h-100"
                    >
                      <SideMenuContent
                        setActiveIndex={changeActiveIndex}
                        activeIndex={activeIndex}
                        hoverIndex={hoveredMenuItem}
                        handleSidebarMouseEnter={handleSidebarMouseEnter}
                        activeItemState={activeItem}
                        handleActiveItem={handleActiveItem}
                        activePath={activePath}
                        lang={currentLang}
                        permission={permission}
                        currentUser={currentUser}
                        collapsedMenuPaths={collapsedMenuPaths}
                        toggleMenu={sidebarVisibility}
                        deviceWidth={props.deviceWidth}
                        userProjects={userProjects}
                        userPermissions={userPermissions}
                      />
                    </ul>
                  </div>
                </Hammer>
              </ScrollbarTag>
            </div>
          </>
        )
      }}
    </ContextLayout.Consumer>
  )
}

export default Sidebar
