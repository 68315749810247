import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Input,
} from 'reactstrap'
import { toggleInviteModal } from '~/store/modules/navbar/actions'
import Field from './commons/Field'
import { Formik, Form, Field as FormikField } from 'formik'
import MultiSelect from '~/components/MultiSelect'
import { Show } from './commons'
import useAxios from 'axios-hooks'
import { Alert } from './Alert'
import { toast } from 'react-toastify'

const initialValues = {
  gmbAccountId: '',
  name: '',
  avatarImage: null,
  accountId: '',
  website: '',
  utmSource: '',
  utmMediumInfo: '',
  utmMediumPost: '',
  avatarLink: null,
  expirationDate: null,
  usersIds: [],
  selectedUsers: [],
}

function CreateProjectForm({ users = [], isValid, isSubmiting }) {
  return (
    <>
      <Row>
        <Col lg="6" className="px-1">
          <Field name="gmbAccountId" label="Group ID" render={Input} required />
        </Col>
      </Row>
      <Show condition={isValid}>
        <Row>
          <Col lg="6" className="px-1 mt-1">
            <Field
              name="name"
              label="Project name"
              render={Input}
              required
              disabled
            />
          </Col>
          <Col lg="6" className="px-1 mt-1">
            <Field
              name="selectedUsers"
              label="Authorized users"
              render={MultiSelect}
              placeholder={<FormattedMessage id="Select autorized users" />}
              onChange={(selected, helpers) => helpers.setValue(selected)}
              errorExtractor={error => error.value}
              options={users}
              menuPortalTarget={
                document.getElementsByClassName('modal')[0] || document.body
              }
              required
              isLoading={isSubmiting}
              isDisabled={isSubmiting}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="px-1 mt-1">
            <div className="custom-checkbox custom-control d-flex">
              <FormikField
                id="importKeywords"
                className="custom-control-input"
                type="checkbox"
                name="importKeywords"
              />
              <label
                className="custom-control-label"
                htmlFor="importKeywords"
                style={{ fontSize: '1em' }}
              >
                <FormattedMessage id="Allow import keywords" />
              </label>
            </div>
          </Col>
        </Row>
      </Show>
    </>
  )
}

function ModalCreateProjectByInvite() {
  const [isValid, setIsValid] = useState(false)
  const [projectData, setProjectData] = useState()
  const [alertVisible, setAlertVisible] = useState(false)
  const [users, setUsers] = useState([])
  const isOpen = useSelector(state => state.navbar.toggleInviteModal)
  const dispatch = useDispatch()
  const toggleModal = () => {
    setIsValid(false)
    setProjectData(null)
    dispatch(toggleInviteModal())
  }

  const [, fetchInvite] = useAxios(
    {
      method: 'GET',
    },
    { manual: true }
  )

  const [, importProject] = useAxios(
    {
      method: 'POST',
    },
    { manual: true }
  )

  const isSubmiting = false

  function fetchProjectByInvite({ gmbAccountId }) {
    fetchInvite({
      url: `/projects/${gmbAccountId}/import`,
    })
      .then(({ data }) => {
        if (data.project) {
          if (data.project.importType === 'restore') {
            return setAlertVisible(true)
          }
          setIsValid(true)
          const { users, ...project } = data.project
          setProjectData(project)
          const userOptions = users?.map(u => ({
            label: u.name,
            value: [u.id],
          }))
          setUsers(userOptions)
        }
      })
      .catch(() =>
        toast.error(
          `Not possible import this project. Incorrect group id or project already imported!`,
          { autoClose: 6000 }
        )
      )
  }

  function handleImportProject(data) {
    const usersIds = data.selectedUsers?.map(u => u.value).flat()
    importProject({
      url: `/projects/${data.gmbAccountId}/import`,
      data: { ...data, usersIds },
    })
      .then(() => setAlertVisible(true))
      .catch(() => toast.error(`Not possible import this project.`))
  }

  const handleSubmit = async values => {
    if (!isValid) return await fetchProjectByInvite(values)

    return await handleImportProject(values)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="modal-lg">
      <Formik
        initialValues={projectData || initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        enableReinitialize
      >
        {() => (
          <Form>
            <ModalHeader toggle={toggleModal} style={{ paddingLeft: '25px' }}>
              <FormattedMessage id="Import project" />
            </ModalHeader>
            <ModalBody className="p-2">
              <p className="mb-2">
                <FormattedMessage id="Enter your group ID below to import the desired project" />
              </p>

              <CreateProjectForm isValid={isValid} users={users} />
            </ModalBody>
            <ModalFooter
              className="d-flex justify-content-end"
              style={{ padding: '20px' }}
            >
              <div className="d-flex">
                <Button
                  outline
                  color="primary"
                  onClick={() => toggleModal()}
                  disabled={isSubmiting}
                >
                  <FormattedMessage id="Cancel" />
                </Button>

                <Button
                  color={'success'}
                  className="ml-2"
                  disabled={isSubmiting}
                  type="submit"
                >
                  <FormattedMessage id={isValid ? 'Import project' : 'Next'} />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
      <Alert
        type="success"
        visible={alertVisible}
        onClose={() => {
          setAlertVisible(false)
          toggleModal()
        }}
        confirmBtnText="Close"
        body={`Project successfully imported! Locations and other data are being imported and may take a few minutes to finish.`}
        title="Success"
      />
    </Modal>
  )
}

export default ModalCreateProjectByInvite
