import mock from '../mock'

export const searchResult = [
  {
    groupTitle: 'General',
    searchLimit: 4,
    data: [
      {
        target: 'Bulk Edit',
        title: 'Bulk Edit',
        link: '/locations/edit',
      },
      {
        target: 'Reviews',
        title: 'Reviews',
        link: '/locations/reviews',
      },
      {
        target: 'Questions',
        title: 'Questions',
        link: '/locations/questions',
      },
    ],
  },
  {
    groupTitle: 'Posts',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'Add Offer',
        title: 'Add Offer',
        link: '/posts/offer',
      },
      {
        id: 2,
        target: `What's New`,
        title: `What's New`,
        link: '/posts/cta',
      },
      {
        id: 3,
        target: 'Event',
        title: 'Event',
        link: '/posts/event',
      },
      {
        id: 4,
        target: 'COVID-19',
        title: 'COVID-19',
        link: '/posts/alert',
      },
    ],
  },
  {
    groupTitle: 'Insights',
    searchLimit: 4,
    data: [
      {
        target: 'Actions',
        title: 'Actions',
        link: '/insights/actions',
      },
      {
        target: 'Locations',
        title: 'Locations',
        link: '/insights/locations',
      },
    ],
  },
]

mock.onGet('/api/main-search/data').reply(200, {
  searchResult,
})
