export const sizes = {
  xls: '320px',
  xl: '475px',
  xll: '625px',
  sm: '768px',
  mdxs: '870px',
  mds: '1024px',
  md: '1280px',
  mdl: '1360px',
  lg: '1440px',
  lgl: '2560px',
}

export const queries = {
  more: {
    xls: `(min-width: ${sizes.xls})`,
    xl: `(min-width: ${sizes.xl})`,
    xll: `(min-width: ${sizes.xll})`,
    sm: `(min-width: ${sizes.sm})`,
    mdxs: `(min-width: ${sizes.mdxs})`,
    mds: `(min-width: ${sizes.mds})`,
    md: `(min-width: ${sizes.md})`,
    mdl: `(min-width: ${sizes.mdl})`,
    lg: `(min-width: ${sizes.lg})`,
    lgl: `(min-width: ${sizes.lgl})`,
  },
  less: {
    xls: `(max-width: ${sizes.xls})`,
    xl: `(max-width: ${sizes.xl})`,
    xll: `(max-width: ${sizes.xll})`,
    sm: `(max-width: ${sizes.sm})`,
    mdxs: `(max-width: ${sizes.mdxs})`,
    mds: `(max-width: ${sizes.mds})`,
    md: `(max-width: ${sizes.md})`,
    mdl: `(max-width: ${sizes.mdl})`,
    lg: `(max-width: ${sizes.lg})`,
    lgl: `(max-width: ${sizes.lgl})`,
  },
}
