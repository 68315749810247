import React from 'react'
import styled from 'styled-components'

export const AppErrorPage = ({ error }) => {
  const message = error?.message
  const stack = error?.stack
  const url = window.location.href
  const userAgent = navigator.userAgent
  const date = new Date().toString()
  const appState = localStorage.getItem('state')
  const userId = JSON.parse(
    JSON.parse(localStorage.getItem('persist:locasty') || '{}')?.user || '{}'
  )?.profile?.id

  const compactError = `APPLICATION ERROR\n${message}\n${url}\n${userAgent}\n${date}\n${userId}\n${appState}${
    stack ? '\n\n' + stack : ''
  }`

  return (
    <ErrorDiv>
      <div>
        <h1>APPLICATION ERROR</h1>
        <div>
          If this error keep happening please report it with the information
          below:
        </div>
        {error && (
          <div className="error-div">
            {message && <pre>{message}</pre>}
            <pre>
              {url}
              {'\n'}
              {userAgent}
              {'\n'}
              {date}
              {'\n'}
              {userId}
              {'\n'}
              {appState}
            </pre>
            <pre>{stack}</pre>
          </div>
        )}
      </div>
      <div className="button-error-menu">
        <button onClick={() => navigator.clipboard.writeText(compactError)}>
          Copy Error
        </button>
        <button onClick={() => window.location.replace('/')}>Go to Home</button>
      </div>
    </ErrorDiv>
  )
}

const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #7367f0;
    color: #fff;
  }

  h2 {
    font-size: 1.2rem;
  }

  .error-div {
    margin-top: 10px;
  }

  .button-error-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  pre {
    padding: 10px;
    border-radius: 8px;
    background-color: #d9d9d9;
  }
`
