import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { FormattedMessage, useIntl } from 'react-intl'

export function Alert({
  type = 'info',
  visible = false,
  onClose,
  onCancel,
  body,
  confirmBtnText = 'Confirm',
  cancelBtnText = 'Close',
  hasCancelBtn = false,
  title,
  ...props
}) {
  const intl = useIntl()
  const cancelProps = {
    confirmBtnText: intl.formatMessage({ id: confirmBtnText }),
    cancelBtnText: intl.formatMessage({ id: cancelBtnText }),
    onCancel: onCancel,
    showCancel: true,
  }

  return (
    <SweetAlert
      info={type === 'info'}
      success={type === 'success'}
      error={type === 'error'}
      show={visible}
      onConfirm={onClose}
      title={intl.formatMessage({ id: title })}
      confirmBtnText={intl.formatMessage({ id: confirmBtnText })}
      {...(hasCancelBtn ? cancelProps : {})}
      {...props}
    >
      <p className="sweet-alert-text">
        <FormattedMessage
          id={body || 'Your locations were NOT updated successfully!'}
        />
      </p>
    </SweetAlert>
  )
}
