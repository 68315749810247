import { takeLatest, put, call, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import axios from 'axios'

import api from '~/services/api'
import { history } from '~/history'

import { signInSuccess, signFailure } from './actions'

export function* signIn({ payload }) {
  try {
    const { email, password } = payload

    const response = yield call(api.post, 'login', {
      email,
      password,
    })

    const { access_token, refresh_token, user } = response.data

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!user.ip) {
      const authorizedIps = user.ip.split(';')

      const { data } = yield call(
        axios.get,
        `https://geolocation-db.com/json/${process.env.GEOLOCATIONDB_KEY}`
      )

      if (!authorizedIps.find(e => e === data.IPv4)) {
        throw Error('Authentication IP Failure')
      }
    }

    api.defaults.headers.Authorization = `Bearer ${access_token}`

    yield put(signInSuccess(access_token, refresh_token, user))
    history.push('/')
  } catch (err) {
    if (err === 'Authentication IP Failure') {
      toast.error('Authentication invalid!')
    } else {
      console.error('Error on signIn \n', err)
      toast.error('Authentication error!')
    }
    yield put(signFailure())
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload

    yield call(api.post, 'users', {
      name,
      email,
      password,
      provider: true,
    })

    history.push('/')
  } catch (err) {
    toast.error('Registration error!')

    yield put(signFailure())
  }
}

export function setToken({ payload }) {
  if (!payload) return

  const { access_token } = payload.auth
  if (access_token) {
    api.defaults.headers.Authorization = `Bearer ${access_token}`
  }
}

export function signOut() {
  localStorage.setItem('state', JSON.stringify({}))
  history.push('/')
}

export function* refreshToken({ payload }) {
  try {
    const { refresh_token, callback } = payload

    const config = {
      headers: {
        Authorization: `Bearer ${refresh_token}`,
      },
    }

    const response = yield call(api.get, '/refresh', config)

    const { access_token, userData } = response.data

    api.defaults.headers.Authorization = `Bearer ${access_token}`

    callback(access_token)

    yield put(signInSuccess(access_token, refresh_token, userData))
  } catch (err) {
    console.error('Error on refreshToken \n', err)
    toast.error('Authentication error!')
    yield put(signFailure())
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/REFRESH_TOKEN', refreshToken),
])
