import axios from 'axios'
import { store } from '~/store'
import { signOut, refreshToken } from '~/store/modules/auth/actions'
import { configure } from 'axios-hooks'
import { toast } from 'react-toastify'

let isAlreadyFetchingAccessToken = false
let subscribers = []
function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
  isAlreadyFetchingAccessToken = false
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

const getRefreshToken = () =>
  JSON.parse(localStorage.getItem('refresh_token')) ||
  store.getState().auth.refresh_token

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use(config => {
  try {
    const locale =
      JSON.parse(localStorage.getItem('locasty_default_lang'))?.locale || 'en'
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    config.headers.common.locale = locale
    config.headers.common.timezone = timezone

    return config
  } catch (error) {
    return config
  }
})

api.interceptors.response.use(
  response => response,
  error => {
    const { config, response: { status } = {} } = error

    const originalRequest = config

    if (status === 401 && config.url.includes('/refresh')) {
      toast.error('Session Expired. Returning to Login Screen')
      store.dispatch(signOut())
      return Promise.reject(error)
    }

    if (status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        store.dispatch(refreshToken(getRefreshToken(), onAccessTokenFetched))
      }

      return new Promise(resolve => {
        addSubscriber(access_token => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`
          resolve(api(originalRequest))
        })
      })
    }

    return Promise.reject(error)
  }
)

configure({ axios: api })

export default api
