export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  }
}

export function signUpRequest(name, email, password) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { name, email, password },
  }
}

export function signInSuccess(access_token, refresh_token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { access_token, refresh_token, user },
  }
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  }
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  }
}

export function refreshToken(refresh_token, callback) {
  return {
    type: '@auth/REFRESH_TOKEN',
    payload: { refresh_token, callback },
  }
}
