import React, { useMemo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Star } from 'react-feather'

const mapRatingToNumber = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
}

function RateShowcase({ rating, starSize = 18, gap = 0, fillColor }) {
  const width = useMemo(() => {
    const rate = _.isString(rating) ? mapRatingToNumber[rating] : rating
    return (starSize + gap) * rate
  }, [rating, starSize, gap])

  return (
    <RateContainer starSize={starSize} gap={gap}>
      <div
        className="d-flex align-items-center"
        style={{ position: 'absolute', gap }}
      >
        {Array.from({ length: 5 }, (_, pos) => (
          <div key={pos}>
            <Star size={starSize} stroke={'#6D7278'} fill="#fff" />
          </div>
        ))}
      </div>
      <div
        className="d-flex align-items-center"
        style={{ position: 'absolute', width, overflow: 'hidden', gap }}
      >
        {Array.from({ length: 5 }, (_, pos) => (
          <div key={pos}>
            <Star
              size={starSize}
              stroke={'#ff9f43'}
              fill={fillColor || '#fff'}
            />
          </div>
        ))}
      </div>
    </RateContainer>
  )
}

const RateContainer = styled.div`
  min-width: ${props => (props.starSize + props.gap) * 5}px;
  min-height: ${props => props.starSize + 6}px;
  position: relative;
`

export default RateShowcase
